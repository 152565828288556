import React, { useState, useEffect } from "react";
import Container from "../components/chat-channel/Container";
import Layout from "../components/chat-channel/Layout";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import styled from "styled-components";
import { useIsMount } from "../react/useIsMount";
import {CookiesPoup} from "./homepage"

const card_one = require("../assets/images/security/01_dp_icon_01.png");
const card_two = require("../assets/images/security/01_dp_icon_02.png");
const card_three = require("../assets/images/security/01_dp_icon_03.png");
const card_four = require("../assets/images/security/01_dp_icon_04.png");
const card_five = require("../assets/images/security/01_dp_icon_05.png");
const card_six = require("../assets/images/security/01_dp_icon_06.png");
const card_7 = require("../assets/images/security/01_dp_icon_07.png");
const card_8 = require("../assets/images/security/01_dp_icon_08.png");
const card_9 = require("../assets/images/security/01_dp_icon_09.png");
const card_10 = require("../assets/images/security/01_dp_icon_10.png");
const card_11 = require("../assets/images/security/01_dp_icon_11.png");
const card_12 = require("../assets/images/security/01_dp_icon_13.png");
const card_13 = require("../assets/images/security/01_dp_icon_15.png");
const card_14 = require("../assets/images/security/01_dp_icon_14.png");
const card_15 = require("../assets/images/security/asset-1.png");

const sd_1 = require("../assets/images/security/02_sd_icon_01.png");
const sd_2 = require("../assets/images/security/02_sd_icon_02.png");
const sd_3 = require("../assets/images/security/02_sd_icon_03.png");
const sd_4 = require("../assets/images/security/02_sd_icon_04.png");
const sd_5 = require("../assets/images/security/02_sd_icon_05.png");
const sd_6 = require("../assets/images/security/02_sd_icon_06.png");
const sd_7 = require("../assets/images/security/02_sd_icon_07.png");

const ha_1 = require("../assets/images/security/03_ha_icon_01.png");
const ha_2 = require("../assets/images/security/03_ha_icon_02.png");
const ha_3 = require("../assets/images/security/03_ha_icon_03.png");

export const Tabs = styled.div`
  overflow: hidden;
  background: #fff;
  font-family: "Gordita-Medium";
  xwidth: 100%;
  float: left;
`;

export const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  width: auto;
  position: relative;
  color: ${props => (props.active ? "#000000" : "#777")};
  margin: 0px 15px;
  font-size: 16px;
  border-bottom: ${props => (props.active ? "2px solid #2468F6" : "")};
  background-color: ${props => (props.active ? "white" : "white")};
  height: ${props => (props.active ? "auto" : "auto")};
  transition: background-color 0.5s ease-in-out;
  padding-bottom: 12px;

  :hover {
    background-color: white;
  }
  :focus {
    outline: 0;
  }
  @media (max-width: 992px) {
    width: 100%;
    margin: 0px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
`;

export const Content = styled.div`
  ${props => (props.active ? "" : "display:none")}
`;

export const dataProtection = [
  [
    {
      image: card_one,
      header: "Virtual Private Cloud",
      content:
        "Workativ is hosted in AWS VPCs in the non-promiscuous mode and further segmented for increased security and manageability."
    },
    {
      image: card_two,
      header: "Access Controls",
      content:
        "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy."
    },
    {
      image: card_three,
      header: "Encryption",
      content:
        "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit."
    }
  ],
  [
    {
      image: card_four,
      header: "Multi-Factor Authentication",
      content: "AWS console login users have MFA token authentication."
    },
    {
      image: card_five,
      header: "Use SSH Keys Authentication",
      content: "Workativ uses PEM key files for server authentication."
    },
    {
      image: card_six,
      header: "SSL Encription",
      content: "All external HTTP requests are secured end to end."
    }
  ],
  [
    {
      image: card_7,
      header: "Malware & Spam Protection",
      content:
        "We regularly apply malware and spam protection based on the latest threat signatures and support real-time scanning and security."
    },
    {
      image: card_8,
      header: "Perimeter Security",
      content:
        "Routing rules are hardened based on pre-established criteria for various permissible transactions across all resources."
    },
    {
      image: card_9,
      header: "Management Plane",
      content:
        "Secure administrative tunnel with whitelisted IP addresses for secure connection to the servers for administrative purposes, through a bastion host."
    }
  ],
  [
    {
      image: card_10,
      header: "Secure Endpoints",
      content:
        "Workativ provides secure transactions with end-to-end encryption and secure server-to-server file transfers."
    },
    {
      image: card_11,
      header: "Maintain Specific Security Groups",
      content:
        "Workativ provides port-based and IP based access to the server for the whitelisted IPs."
    },
    {
      image: card_12,
      header: "Private Network Database",
      content:
        "Our database servers remove connection attempts or access from the Internet."
    }
  ],
  [
    {
      image: card_13,
      header: "AWS Cloud Trail",
      content: "We monitor AWS console user log events."
    },
    {
      image: card_14,
      header: "Alerting Systems",
      content: "We monitor Service and Application logs through alerts."
    },
    {
      image: card_15,
      header: "Update and Upgrade Software Regularly",
      content: "We update server and security patches regularly."
    }
  ]
];

const secureDevelopment = [
  [
    {
      image: sd_1,
      header: "Segregation of Duties",
      content:
        "Access to the production is restricted to a minimal set of users based on job roles. Access to the production environment for developers and Quality Assurance team members are limited based on their job responsibilities."
    },
    {
      image: sd_2,
      header: "Code Review",
      content:
        "The Quality Assurance team tests all changes and criteria established for performing code reviews, web vulnerability assessment, and advanced security tests."
    },
    {
      image: sd_3,
      header: "Version Control",
      content:
        "The source code is managed centrally with version controls, and access restricted based on various teams assigned to specific sprints. Records are maintained for code changes and code check-ins and check-outs."
    }
  ],
  [
    {
      image: sd_4,
      header: "Microcontainer Deployments",
      content:
        "We use docker virtualization technology for service isolation and high availability."
    },
    {
      image: sd_5,
      header: "Quality Assurance",
      content:
        "Builds undergo stringent functionality tests, performance tests, stability tests, and UX tests before being certified for go-live."
    },
    {
      image: sd_6,
      header: "DevOps Squad",
      content:
        "Our DevOps sprints are led by a multidisciplinary Squad of members, including the Product Owner, Squad Lead, Tribe Lead and Members, and Quality Assurance."
    }
  ],
  [
    {
      image: sd_7,
      header: "Product Roadmapping",
      content:
        "Product road-map is defined and reviewed periodically by the ‘Head of Products’. Security fixes are prioritized and bundled in the earliest possible sprint."
    }
  ]
];
const highAvailability = [
  [
    {
      image: ha_1,
      header: "Platform Load Balancing",
      content:
        "Workativ automatically distributes application traffic across multiple availability zones that support high availability, auto-scaling, and robust security."
    },
    {
      image: ha_2,
      header: "Capacity Management",
      content:
        "We undertake proactive capacity monitoring based on conservative thresholds and on-demand capacity expansion capability through our highly elastic hosting partners."
    },
    {
      image: ha_3,
      header: "Component Redundancy",
      content:
        "All components are deployed in ‘n+1’ mode across multiple availability zones configured in active-active mode behind a load balancing service."
    }
  ]
];
const menu = ["  Data Protection", "Secure Development", " High Availability"];

export default function SecurityPage() {
  const [active, setActive] = useState(0);
  const isNotMount = useIsMount();

  //  Scroll To when Click mobile menu
  useEffect(() => {
    // console.log('moubt', isNotMount)
    if (!isNotMount) {
      // console.log("scrolling")
      window.scrollTo(0, 360);
    }
  }, [active]);
  //  Scroll To when Click mobile menu

  const handleClick = e => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <TitleAndMetaTags
        title="Security | Workativ Assistant"
        description="Workativ Assistant understands that the confidentiality, integrity and availability of customer data and assets are vital to all organizations, regardless of size."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Security | Workativ Assistant"
        ogDescription="Workativ Assistant understands that the confidentiality, integrity and availability of customer data and assets are vital to all organizations, regardless of size."
      />
      <Container>
      <Layout logoFor="WORKATIV" product="WORKATIV">
          <div className="container-fluid security_page">
            <div className="row">
              <section className="top_section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="main-slider-left">
                        <h1>Security</h1>
                        <p>
                          Workativ has implemented robust security processes and
                          controls that are in compliance with industry-leading
                          standards and regulations. Workativ ensures data
                          security, operational security, and physical security
                          on our platform with highly secure and reliable
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="tab_desktop">
                <div className="container">
                  <div className="row">
                    <Tabs>
                      <Tab onClick={handleClick} active={active === 0} id={0}>
                        Data Protection
                      </Tab>

                      <Tab onClick={handleClick} active={active === 1} id={1}>
                        Secure Development
                      </Tab>
                      <Tab onClick={handleClick} active={active === 2} id={2}>
                        High Availability
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </section>
              <>
                <div className="mob_res_menu" id="root">
                  <div className="col-md-12 col-sm-12 col-xs-12 --sh-sm tab_resposnive">
                    <div className="dropdown wv-dropdown">
                      <button
                        className={`link ${isOpen ? "active" : ""}`}
                        onClick={() => setOpen(!isOpen)}
                      >
                        {menu[active]}
                      </button>
                      <div
                        onClick={() => setOpen(!isOpen)}
                        id="myDropdown"
                        className={`accordion-item submenu dropdown-content ${
                          !isOpen ? "collapsed" : ""
                        }`}
                      >
                        <Tab onClick={handleClick} active={active === 0} id={0}>
                          Data Protection
                        </Tab>

                        <Tab onClick={handleClick} active={active === 1} id={1}>
                          Secure Development
                        </Tab>
                        <Tab onClick={handleClick} active={active === 2} id={2}>
                          High Availability
                        </Tab>
                      </div>
                    </div>
                  </div>
                  <Content className="security_wrapper" active={active === 0}>
                    {dataProtection.map((data, index) => {
                      // console.log("rrr", dataProtection);
                      if (index % 2 == 0) {
                        return (
                          <div className="resources_card">
                            <section className="whitepaper_cards">
                              <div className="container">
                                <div className="row">
                                  <ul className="cards">
                                    {data.map(data => (
                                      <li className="cards__item">
                                        <div className="card">
                                          <div className="card__image">
                                            <img
                                              src={data.image}
                                              alt={data.header}
                                            ></img>
                                          </div>
                                          <div className="card__content">
                                            <div className="card__title">
                                              {data.header}
                                            </div>
                                            <p className="card__text">
                                              {data.content}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </section>
                          </div>
                        );
                      }
                      return (
                        <div className="resources_card bg_ccc">
                          <section className="whitepaper_cards">
                            <div className="container">
                              <div className="row">
                                <ul className="cards">
                                  {data.map(data => (
                                    <li className="cards__item">
                                      <div className="card">
                                        <div className="card__image">
                                          <img
                                            src={data.image}
                                            alt={data.header}
                                          ></img>
                                        </div>
                                        <div className="card__content">
                                          <div className="card__title">
                                            {data.header}
                                          </div>
                                          <p className="card__text">
                                            {data.content}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </section>
                        </div>
                      );
                    })}
                  </Content>
                  <Content className="security_wrapper" active={active === 1}>
                    {secureDevelopment.map((data, index) => {
                      // console.log("rrr", secureDevelopment);
                      if (index % 2 == 0) {
                        return (
                          <div className="resources_card">
                            <section className="whitepaper_cards">
                              <div className="container">
                                <div className="row">
                                  <ul className="cards">
                                    {data.map(data => (
                                      <li className="cards__item">
                                        <div className="card">
                                          <div className="card__image">
                                            <img
                                              src={data.image}
                                              alt={data.header}
                                            ></img>
                                          </div>
                                          <div className="card__content">
                                            <div className="card__title">
                                              {data.header}
                                            </div>
                                            <p className="card__text">
                                              {data.content}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </section>
                          </div>
                        );
                      }
                      return (
                        <div className="resources_card bg_ccc">
                          <section className="whitepaper_cards">
                            <div className="container">
                              <div className="row">
                                <ul className="cards">
                                  {data.map(data => (
                                    <li className="cards__item">
                                      <div className="card">
                                        <div className="card__image">
                                          <img
                                            src={data.image}
                                            alt={data.header}
                                          ></img>
                                        </div>
                                        <div className="card__content">
                                          <div className="card__title">
                                            {data.header}
                                          </div>
                                          <p className="card__text">
                                            {data.content}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </section>
                        </div>
                      );
                    })}
                  </Content>
                  <Content className="security_wrapper" active={active === 2}>
                    {highAvailability.map((data, index) => {
                      // console.log("rrr", highAvailability);
                      return (
                        <div className="resources_card">
                          <section className="whitepaper_cards">
                            <div className="container">
                              <div className="row">
                                <ul className="cards">
                                  {data.map(data => (
                                    <li className="cards__item">
                                      <div className="card">
                                        <div className="card__image">
                                          <img
                                            src={data.image}
                                            alt={data.header}
                                          ></img>
                                        </div>
                                        <div className="card__content">
                                          <div className="card__title">
                                            {data.header}
                                          </div>
                                          <p className="card__text">
                                            {data.content}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </section>
                        </div>
                      );
                    })}
                  </Content>
                </div>
              </>
            </div>
          </div>
          {/* <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}
